import React, { useReducer, useEffect, useState, useMemo } from "react";

import { getMember } from "../../services/memberAPI";
import { getServicesByStatus } from "../../services/servicesAPI";
import { saveInvoice as postInvoice } from "../../services/invoiceAPI";

import Form from "./Form";
import InvoiceFormat from "./InvoiceFormat";

const initialState = {
  form: {
    services: [],
    quantity: 0,
    discount: 0,
    fetch: false,
    service: {},
    price: 0,
    compost: false,
    isPrint: false,
    paymentType: "cash",
    notes: "",
  },
  invoice: {
    id: "",
    member: {},
    date: "",
    total: 0,
    services: [],
    status: "",
    paymentType: "",
  },
  
};

const initialValidationError = {
  quantity: "",
  service: "",
  member: "",
  price: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "fetch_services":
      return {
        ...state,
        form: {
          ...state.form,
          fetch: true,
        },
      };
    case "fetched_services":
      return {
        ...state,
        form: {
          ...state.form,
          fetch: false,
          services: action.payload,
        },
      };
    case "change_prop_form":
      return {
        ...state,
        form: {
          ...state.form,
          [action.name]: action.value,
        },
      };
    case "reset_quantity":
      return {
        ...state,
        form: {
          ...state.form,
          quantity: 0,
          discount: 0,
        },
      };
    case "change_prop_invoice":
      return {
        ...state,
        invoice: {
          ...state.invoice,
          [action.name]:
            action.name === "services"
              ? [...state.invoice.services, action.value]
              : action.value,
        },
      };
    case "remove_invoice_services":
      let indexOf = state.invoice.services
        .map((service) => service.value)
        .indexOf(action.value);
      return {
        ...state,
        invoice: {
          ...state.invoice,
          services: [
            ...state.invoice.services.slice(0, indexOf),
            ...state.invoice.services.slice(
              indexOf + 1,
              state.invoice.services.length
            ),
          ],
        },
      };
    case "modified_invoice_services":
      let index = state.invoice.services
        .map((service) => service.value)
        .indexOf(action.service.value);
      return {
        ...state,
        invoice: {
          ...state.invoice,
          services: [
            ...state.invoice.services.slice(0, index),
            action.service,
            ...state.invoice.services.slice(
              index + 1,
              state.invoice.services.length
            ),
          ],
        },
      };
    case "clean_form":
      return {
        ...state,
        form: {
          ...state.form,
          quantity: 0,
          price: 0,
          service: {},
          fetch: false,
          notes: "",
          isPrint: false,
        },
        invoice: {
          ...initialState.invoice,
          date: action.date,
          status: "new",
        },
      };
   
    default:
      return state;
  }
}

function validationReducer(state, action) {
  switch (action.type) {
    case "change":
      return {
        ...state,
        [action.name]: action.value,
      };
    case "reset_disc_quantity":
      return {
        ...state,
        discount: "",
        quantity: "",
      };
    default:
      return state;
  }
}

let dateObj = new Date(Date.now());
let invoiceForPrint = {};
function FormContainer({ id }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validState, dispatchValid] = useReducer(
    validationReducer,
    initialValidationError
  );


  function onChangeInput({ target: { value, name } }) {
    dispatch({ type: "change_prop_form", name, value });
    if (name === "discount" || name === "quantity") {
      dispatchValid({
        type: "reset_disc_quantity",
      });
    }
  }
  function onDropDownChange(newValue, { name }) {
    if (name === "member") {
      if (!!validState.member) {
        dispatchValid({ type: "change", name: "member", value: "" });
      }
      if (newValue.body.status === "inactive" && !state.form.compost) {
        onCompostMember();
      } else {
        cleanCompostMember();
      }

      dispatch({
        type: "change_prop_invoice",
        name: name,
        value: {
          value: newValue.value,
          label: newValue.label,
          body: {
            identifier: newValue.body.identifier,
            status: newValue.body.status,
            familyNames: newValue.body.familyNames,
            names: newValue.body.names,
            membershipToPay: newValue.body.membershipToPay,
            _id: newValue.body._id,
          },
        },
      });
    } else if (name === "service") {
      if (!!validState.service) {
        dispatchValid({ type: "change", name: "service", value: "" });
      }
      if (
        Object.keys(state.invoice.member).length &&
        state.invoice.member.body.status === "inactive" &&
        !state.form.compost
      ) {
        onCompostMember();
      }
      dispatch({
        type: "change_prop_form",
        name: "price",
        value: newValue.service.price,
      });
      dispatch({
        type: "change_prop_form",
        name: name,
        value: {
          value: newValue.value,
          label: newValue.label,
          service: newValue.service,
        },
      });
    }
  }
  function onCompostMember() {
    dispatch({
      type: "change_prop_form",
      name: "compost",
      value: true,
    });
    setMsg("compost");
    let memberShipService = state.invoice.services.find(
      (service) => service.body.isSystem && service.body.isMembership
    );

    if (memberShipService && memberShipService.quantity < 3) {
      memberShipService.quantity = 3;
      dispatch({
        type: "modified_invoice_services",
        service: memberShipService,
      });
    } else {
      memberShipService = state.form.services.find(
        (service) => service.service.isSystem && service.service.isMembership
      );
      if (memberShipService) {
        memberShipService.quantity = 3;
        memberShipService.price = memberShipService.service.price;
        dispatch({
          type: "change_prop_invoice",
          name: "services",
          value: {
            value: memberShipService.value,
            label: memberShipService.label,
            quantity: memberShipService.quantity,
            price: memberShipService.price,
            body: memberShipService.service,
          },
        });
      }
    }
  }
  function cleanCompostMember() {
    dispatch({
      type: "change_prop_form",
      name: "compost",
      value: false,
    });
    setMsg("");
  }
  function onAddService() {
    if (!Object.keys(state.form.service).length) {
      dispatchValid({
        type: "change",
        name: "service",
        value: "Seleccione un servicio",
      });
    } else if (!state.form.quantity) {
      dispatchValid({
        type: "change",
        name: "quantity",
        value: "El valor debe ser mayor que cero",
      });
    } else if (state.form.discount > 100) {
      //(state.form.quantity * state.form.service.service.price)) {
      dispatchValid({
        type: "change",
        name: "discount",
        value: `El valor debe ser menor que 100`,
      });
    } else {
      let service = state.invoice.services.find(
        (service) => service.value === state.form.service.value
      );
      if (service) {
        service.quantity = +service.quantity + +state.form.quantity;
        service.discount = state.form.discount;
        dispatch({ type: "modified_invoice_services", service });
      } else {
        dispatch({
          type: "change_prop_invoice",
          name: "services",
          value: {
            value: state.form.service.value,
            label: state.form.service.label,
            quantity: state.form.quantity,
            discount: state.form.discount,
            price: state.form.service.service.price,
            body: state.form.service.service,
          },
        });
      }
      dispatch({ type: "reset_quantity" });
    }
  }
  function removeServices(id) {
    dispatch({ type: "remove_invoice_services", value: id });
  }
  function decreaseServiceQuantity(id) {
    let service = state.invoice.services.find(
      (service) => service.value === id
    );
    if (service.quantity === 1) {
      removeServices(id);
    } else {
      service.quantity = +service.quantity - 1;
      dispatch({ type: "modified_invoice_services", service });
    }
  }

  function newInvoice() {
    dispatch({
      type: "clean_form",
      date: dateObj,
    });
    setSuccess(false);
    setMsg("");
  }

  async function fetchMember(inputValue, callback) {
    try {
      let { body: payload } = await getMember("all", 0, 50, inputValue);
      if (payload.data && payload.data.length) {
        let members = payload.data.map((member) => ({
          value: member.id,
          label: member.familyName + " " + member.names,
          body: member,
        }));
        callback(members);
      }
    } catch (error) {
      console.error("ERROR ", error);
    }
  }

  async function saveInvoice() {
    try {
      setLoading(true);
      if (!state.invoice.services.length || !state.invoice.member.label) {
        dispatchValid({
          type: "change",
          name: "service",
          value: "Campos requeridos",
        });
        dispatchValid({
          type: "change",
          name: "member",
          value: "Campos requeridos",
        });
        setLoading(false);
        return;
      }
      let servicesFormat = [];
      let totalService = 0;
      let totalDiscount = 0;
      for (let x = 0; x < state.invoice.services.length; x++) {
        let service = state.invoice.services[x];
        servicesFormat.push({
          description: service.label,
          isMembership: service.body.isMembership,
          price: service.price,
          quantity: service.quantity,
          discount: service.discount,
        });
        totalService += service.quantity * service.price;
        totalDiscount += service.discount;
      }

      let invoice = {
        ...state.invoice,
        services: servicesFormat,
        total: totalService - totalDiscount,
        totalDiscount: totalDiscount,
        paymentType: state.form.paymentType,
        member: {
          identifier: state.invoice.member.body.identifier,
          fullName: state.invoice.member.label,
          _id: state.invoice.member.body._id,
        },
        status: "paid",
        notes: state.form.notes,
      };
      let { body: payload } = await postInvoice(invoice);
      setLoading(false);
      if (payload.success) {
        setSuccess(true);
        setMsg("serverSuccess");
        dispatch({
          type: "change_prop_invoice",
          name: "state",
          value: "save",
        });
        dispatch({
          type: "change_prop_invoice",
          name: "id",
          value: payload.data.id,
        });

        printInvoice(payload.data.id);
      } else {
        setSuccess(false);
        setMsg("serverError");
      }
    } catch (error) {
      setLoading(false);
    }
  }

  function printInvoice(id) {
    if (state.invoice.services.length && !!state.invoice.member.label) {
      let servicesFormat = [];
      state.invoice.services.forEach((service) => {
        servicesFormat.push({
          description: service.label,
          isMembership: service.body.isMembership,
          price: service.price,
          quantity: service.quantity,
          discount: service.discount,
        });
      });

      invoiceForPrint = {
        ...state.invoice,
        client: {
          name: state.invoice.members.label,
          identifier: state.invoice.members.body.identifier,
        },
        services: servicesFormat,
        notes: state.form.notes,
        paymentType: state.form.paymentType,
        id: id ? id : state.invoice.id ? state.invoice.id : "",
      };

      dispatch({
        type: "change_prop_form",
        name: "isPrint",
        value: true,
      });
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  useEffect(() => {
    (async () => {
        let { body: servicesFetched } = await getServicesByStatus("active");
        if (servicesFetched.success) {
          let services = [];
          servicesFetched.data.forEach((service) => {
            services[services.length] = {
              value: service.id,
              label: service.description,
              service,
            };
          });
          dispatch({ type: "fetched_services", payload: services });
        }
    })();
  }, []);

 

  return (
    <>
      <Form
        validState={validState}
        state={state}
        form={state.form}
        loading={loading}
        success={success}
        isDisabled={
          state.invoice.status === "" || state.invoice.state === "save"
        }
        canPrint={state.invoice.status === "save"}
        onChangeInput={onChangeInput}
        onDropDownChange={onDropDownChange}
        onAddService={onAddService}
        decreaseServiceQuantity={decreaseServiceQuantity}
        newInvoice={newInvoice}
        fetchMember={fetchMember}
        saveInvoice={saveInvoice}
        removeServices={removeServices}
        msg={msg}
        printInvoice={printInvoice}
      />
      {state.form.isPrint && (
        <InvoiceFormat {...invoiceForPrint} isPrint={state.form.isPrint} />
      )}
    </>
  );
}

export default FormContainer;
