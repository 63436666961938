import API from "../config/api";

const BASE = "service";

export function saveService(service) {
  return API.post(`service/save`, service);
}

export function getServicesByStatus(status = "active", text, isMembership, usedBy) {
  let query = `?status=${status}&isMembership=${isMembership}`;

  if (!!text) {
    query += `&text=${text}`;
  }

  if (usedBy) {
    query += `&used_by=${usedBy}`;
  }

  return API.get(`service/all${query}`);
}

export function getService(id) {
  return API.get(`service/id/${id}`);
}

export function updateService(service) {
  return API.put(`${BASE}/update`, service);
}
