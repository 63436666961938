function reducer(state, action) {
  switch (action.type) {
    case "FETCH_SERVICES_SUCCESS":
      return {
        ...state,
        services: fillServices(action.payload),
        isLoading: false,
        isUpdating: false,
        errorHttp: ""
      };
    case "FETCH_SERVICES_REQUEST":
      return {
        ...state,
        isLoading: true,
        isUpdating: false,
        errorHttp: ""
      };
    case "FETCH_SERVICES_FAILURE":
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        msg: "Hubo un error al intentar buscar los servicios",
        variant: "error"
      };
    case "UPDATE_SERVICE_FAILURE":
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        msg: "Hubo un error al intentar actualizar el servicio",
        variant: "error"
      };
    case "UPDATE_SERVICE_REQUEST":
      return {
        ...state,
        isLoading: false,
        isUpdating: true,
        errorHttp: ""
      };
    case "UPDATE_SERVICE_SUCCESS":
      return {
        ...state,
        services: action.payload,
        isLoading: false,
        isUpdating: false,
        errorHttp: ""
      };
    case "SEARCH_TEXT":
      return {
        ...state,
        searchText: action.payload
      }
    case "DENIED_READ_SERVICES":
      return {
        ...state,
        showDeniedMsg: true
      }  
    default:
      return state;
  }
}

function fillServices(services) {
  let newServices = new Map();
  services.forEach(service => {
    newServices.set(service._id, service);
  });
  return newServices;
}

export default reducer;
