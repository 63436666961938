import React from "react";
import { Navigate, useLocation, Outlet } from "react-router";
import useLocalStorage from "../../hooks/useLocalStorage";

function PrivateRouter () {
  const [storage] = useLocalStorage("secret");
  const location = useLocation();
  
  if (!storage || storage === undefined) {
    return <Navigate to={{
      pathname: "/login", state: {from: location }}} replace />;
      
  }
 
  return <Outlet/>
}


export default PrivateRouter;
