import React from "react";
import clsx from "classnames";
import AsyncSelect from "react-select/async";
import {
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  TextField,
  FormLabel,
} from "@mui/material";
import styles from "./InvoiceOwner.module.scss";

const MIN_CHARS = 2;
let debounceTimeout = null;
const debounce = (fetchOptions, wait, ownerType) => (text, callback) => {
  clearTimeout(debounceTimeout);
  if (text && text.length >= MIN_CHARS) {
    debounceTimeout = setTimeout(() => {
      fetchOptions(text.trim(), callback, ownerType);
    }, wait);
  } else {
    callback();
  }
};

const formatOptionLabel = (item) => {
  return (
    <p>
      {item?.body?.isAspiring ? (
        <span className={styles.aspiringTag}>A</span>
      ) : null}
      {item.label}
    </p>
  );
};
function InvoiceOwner({
  ownerTypeOveright,
  fetchItems,
  onDropDownChange,
  value,
  isDisabled,
  validState,
  onInvoiceChange,
  ownerType,
  personName,
  personReference,
  verifyServiceOwnership,
}) {
  const onChangeOwnerType = (e) => {
    onInvoiceChange(e.target.name, e.target.value);
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    onInvoiceChange("owner", {
      label: personName,
      value: personReference,
      [name]: value,
    });
  };

  return (
    <div>
      {ownerTypeOveright ? (
        <label>{ownerTypeOveright === "member" ? "Miembro" : "Compañia"}</label>
      ) : (
        <FormControl>
          <FormLabel id="titular">Titular</FormLabel>
          <RadioGroup
            row
            aria-labelledby="titular"
            name="titular-radio"
            onChange={onChangeOwnerType}
          >
            <FormControlLabel
              value="member"
              control={
                <Radio name="ownerType" checked={ownerType === "member"} />
              }
              label="Miembro"
            />
            <FormControlLabel
              value="aspiring"
              control={
                <Radio name="ownerType" checked={ownerType === "aspiring"} />
              }
              label="Aspirante"
            />
            <FormControlLabel
              value="company"
              control={
                <Radio name="ownerType" checked={ownerType === "company"} />
              }
              label="Compañia"
            />
            <FormControlLabel
              value="person"
              control={
                <Radio name="ownerType" checked={ownerType === "person"} />
              }
              label="Individuo"
            />
          </RadioGroup>
        </FormControl>
      )}
      {ownerType === "person" ? (
        <div className={styles.personForm}>
          <TextField
            name="label"
            id="filled-basic"
            className={styles.textField}
            label="Nombre completo"
            margin="normal"
            variant="filled"
            onChange={onHandleChange}
            value={personName}
            error={!!validState.owner}
            helperText={validState.owner}
            disabled={isDisabled}
            required
          />
          <TextField
            name="value"
            id="filled-basic"
            className={styles.textField}
            label="Referencia"
            margin="normal"
            variant="filled"
            onChange={onHandleChange}
            value={personReference}
            error={!!validState.owner}
            helperText={""}
            disabled={isDisabled}
            required
          />
        </div>
      ) : (
        <AsyncSelect
          classNamePrefix="service"
          className={clsx({ [styles.invoice_owner__error]: !!validState })}
          name={ownerTypeOveright ? ownerTypeOveright : "owner"}
          loadOptions={debounce(fetchItems, 700, ownerType)}
          onChange={onDropDownChange}
          value={value}
          isDisabled={isDisabled}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          {...(ownerType === "aspiring" && {
            formatOptionLabel: formatOptionLabel,
          })}
        />
      )}
    </div>
  );
}

export default InvoiceOwner;
