import React from "react";
import { bool, func, array } from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  Icon,
} from "@mui/material";
import { Link } from "react-router";
import { formatCurrency } from "../../utils/utils";
function TableServices({
  servicesArray,
  stageServicesForDisable,
  loading,
  canDelete,
}) {
  return (
    <>
      {loading ? (
        <>
          <CircularProgress />
          <p>Cargando...</p>
        </>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">No.</TableCell>
              <TableCell align="left">Descripción</TableCell>
              <TableCell align="left">Moneda</TableCell>
              <TableCell align="left">Precio</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Opción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servicesArray.map((row, index) => (
              <TableRow key={`service-${index}`}>
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.currency}</TableCell>
                <TableCell align="left">{formatCurrency(row.price)}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">
                  {!row.isSystem && (
                    <>
                      <Link
                        to={`/servicios/${row.id}`}
                        state={{ edit: true, member: row }}
                      >
                        <Icon>create</Icon>
                      </Link>
                      {canDelete ? (
                        <IconButton
                          onClick={() => stageServicesForDisable(row)}
                        >
                          <Icon>delete_forever</Icon>
                        </IconButton>
                      ) : null}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

TableServices.propTypes = {
  servicesArray: array,
  stageServicesForDisable: func.isRequired,
  loading: bool,
};
export default TableServices;
