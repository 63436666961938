import { Button } from "@mui/material";
import { useNavigate, } from "react-router";
import useLocalStorage from "../../hooks/useLocalStorage";
import styles from "./logout.module.scss";


const LogOut = () => {
    const [, setStorage] = useLocalStorage("secret", null);
    const [, setStoragePermissions] = useLocalStorage("permissions", null);
    
    const navigate = useNavigate();

    const handleLogOut = () => {
      setStorage(null);
      setStoragePermissions(null);
      navigate("/login");
    }

    const handleOnCancel = () => {
        navigate("miembros");
    }

    return (<div>
        <h3>Quieres cerrar sesión?</h3>
        <div>
            <Button onClick={handleOnCancel}>Cancel</Button>
            <Button onClick={handleLogOut}>Cerrar sesión</Button>
        </div>
    </div>)
}

export default LogOut;
