import React from "react";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import clsx from "clsx";
import styles from "./rowSettings.module.scss";

function RowSettings({
  form: { codeSchool, membershipToPay, isUnCompleted, isDeceased, sequence, presidentRole, recurringPayment  },
  handleForm,
  handleFormCheckbox,
  isAspiring
}) {
  return (
    <div className={styles.top_row_setting}>
      {!isAspiring ?
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={isUnCompleted}
              onChange={handleFormCheckbox}
              name="isUnCompleted"
            />
          }
          label="Incompleto"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isDeceased}
              onChange={handleFormCheckbox}
              name="isDeceased"
            />
          }
          label="Fallecido"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={presidentRole}
              onChange={handleFormCheckbox}
              name="presidentRole"
            />
          }
          label="Role de presidente"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={recurringPayment}
              onChange={handleFormCheckbox}
              name="recurringPayment"
            />
          }
          label="Pago recurrentes"
        />
      </div>
      : null }
      <div className="top_row_setting__info">
        <TextField
          name="codeSchool"
          value={codeSchool}
          label="Código Colegio"
          margin="normal"
          fullWidth
          onChange={handleForm}
        />
        <TextField
          name="sequence"
          value={sequence}
          label="No. Miembro"
          margin="normal"
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          className={clsx(styles.inputMedium, styles.inputRightSpace)}
        />
        <TextField
          name="membershipToPay"
          value={membershipToPay}
          label="Membresía pendientes"
          margin="normal"
          fullWidth
          onChange={handleForm}
          type="number"
          readOnly
        />
      </div>
    </div>
  );
}

export default RowSettings;
