import React from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { ButtonLink } from "../..";
import styles from "./topRow.module.scss";

export const TopRow = ({canCreate}) => {
  return (
    <div className={styles.container}>
      <ButtonLink disabled={!canCreate} to="servicios/nuevo">
        <AddCircleOutlineOutlinedIcon /> Nuevo servicio
      </ButtonLink>
    </div>
  );
};
