import React, { useEffect, useState, useMemo } from 'react';
import { findAllcatalogoCuenta } from '../../../services/catalogoCuentaAPI';
import useLocalStorage from "../../../hooks/useLocalStorage";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from "@mui/material";

const CatalogoCuentaList = () => {
    const [catalogoCuenta, setCatalogoCuenta] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deniedMsg, setDeniedMsg] = useState(false);
    const [permissionsStorage] = useLocalStorage("permissions");

    const permissions = useMemo(() => {
      if (!permissionsStorage) {
        return {};
      }
      return (
        JSON.parse(permissionsStorage).find((p) => p.modelName === "accounting") || {}
      );
    }, [permissionsStorage]);

    useEffect(() => {
        (async () => {
          try {
            if (permissions.canRead) {
              const payload = await findAllcatalogoCuenta();
              if (payload.body.success) {
                  setLoading(false);
                  setCatalogoCuenta(payload.body.data);
              }
            } else {
              setDeniedMsg(true);
              setLoading(false);
            }
            
          } catch (e) {}
        })()
    },[])


    if (deniedMsg) {
      return (
        <div><h1>No tiene acceso</h1><p>Su usuario no tiene permiso para ver las cuentas contables.</p></div>
      )
    }

    return (
        <>
        <h2>Catalogo de cuentas</h2>
        {loading ? (
        <>
          <CircularProgress />
          <p>Cargando...</p>
        </>
      ) : (
        <Table>
          <TableHead>
              <TableRow>
                <TableCell align="left">No.</TableCell>
                <TableCell align="left">Codigo</TableCell>
                <TableCell align="left">Descripcion</TableCell>
                <TableCell align="left">Cuenta</TableCell>
                <TableCell align="left">Naturaleza</TableCell>
                <TableCell align="left">Divisa</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
            {
                catalogoCuenta.map((cuenta, index) => (
                    <TableRow
                    key={`${index}-${cuenta.code}`}
                    className={
                        cuenta.activa
                        ? "row_active"                        
                        : "row_disable"
                    }
                  >
                    <TableCell>{index}</TableCell>
                    <TableCell>{cuenta.code}</TableCell>
                    <TableCell>{cuenta.descripcion}</TableCell>
                    <TableCell>{cuenta.numero}</TableCell>
                    <TableCell>{cuenta.naturaleza}</TableCell>
                    <TableCell>{cuenta.divisa}</TableCell>
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      )}
        </>
    )
}

export default CatalogoCuentaList;
