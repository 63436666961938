import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import API from "../../../config/api";
import styles from "./trainingList.module.scss";

const TrainingList = ({ onClose, onGenerate, download, reportMetadata }) => {
  const [training, setTraining] = useState([]);
  const [selectedTraining, setSelectedTraining] = useState("");

  useEffect(() => {
    (async () => {
      const data = await API.get(`taller_for_participant/training`);
      const trainingData = data.body.data.map((t) => {
        return {
          id: t.id,
          name: t.name,
        };
      });
      trainingData.push({id: 'T1-11-2024-tiva_y_ventiliacion', name: 'Tiva y Ventilacion'})
      setTraining(trainingData);
    })();
  }, []);

  const onHandleChange = (e) => {
    const { value } = e.target;
    setSelectedTraining(value);
  };

  async function handelOnGenerate() {
    if (selectedTraining) {
      const downloadUrl = `${reportMetadata.url}?id=${selectedTraining}`;
      await download(downloadUrl, reportMetadata.fileName);
      onClose();
    }
  }
  
  return (
    <div className={styles.container}>
      <p htmlFor="selectReport" className={styles.description}>
        Favor de elejir el taller.
      </p>
      <div>
        <InputLabel id="talleres">Talleres</InputLabel>
        <FormControl fullWidth className={styles.input_space}>
          <Select
            id="talleres"
            value={selectedTraining}
            label="Elija un reporte"
            onChange={onHandleChange}
          >
            {training.length > 0 ? (
              training.map((t) => <MenuItem value={t.id}>{t.name}</MenuItem>)
            ) : (
              <MenuItem value="">Cargando la lista de tallers...</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <div>
        <Button variant="contained" color="primary" onClick={handelOnGenerate}>
          Generar
        </Button>

        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
      </div>
    </div>
  );
};

export default TrainingList;
