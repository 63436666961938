import React from "react";
import { bool, array } from "prop-types";
import { Link } from "react-router";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Icon,
  IconButton,
} from "@mui/material";
import { formatCurrency } from "../../utils/utils";

export function TableInvoiceCredit({
  loading,
  invoiceCredit,
  loadPrintAndShow,
}) {
  return (
    <>
      {loading ? (
        <>
          <CircularProgress />
          <p>Cargando...</p>
        </>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">No.</TableCell>
              <TableCell align="left">No. Fact</TableCell>
              <TableCell align="left">Titular</TableCell>
              <TableCell align="left">Monto</TableCell>
              <TableCell>Pagado</TableCell>
              <TableCell align="left">Fecha</TableCell>
              <TableCell align="left">Opción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceCredit && invoiceCredit.length ? (
              invoiceCredit.map((row, idx) => (
                <TableRow key={`company-${idx}`}>
                  <TableCell align="left">{idx + 1}</TableCell>
                  <TableCell align="left">{row.invoiceId}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="left">
                    {formatCurrency(row.total)}
                  </TableCell>
                  <TableCell>{formatCurrency(row.totalQuota)}</TableCell>
                  <TableCell align="left">{row.date}</TableCell>
                  <TableCell align="left">
                    <Link to={`/recibos/credito/nuevo/${row.invoiceId}`}>
                      <Icon>add_box</Icon>
                    </Link>
                    <IconButton
                      onClick={() => {
                        loadPrintAndShow(row);
                      }}
                    >
                      <Icon className="table_invoice">local_printshop</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <p>No existe ningun registro.</p>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
}

TableInvoiceCredit.propTypes = {
  loading: bool,
  providers: array,
};
