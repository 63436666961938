import * as React from "react";
import { TextField, Grid, Button } from "@mui/material";
import styles from "./invoiceDetail.module.scss";

function InvoiceDetail({ onClose, download, reportMetadata }) {
  const [dates, setDates] = React.useState({ dateIn: "", dateFn: "" });
  const [error, setError] = React.useState("");

  function handleChangeDate({ target: { name, value } }) {
    setDates((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  async function getReport() {
    try {
      const downloadUrl = `${reportMetadata.url}?dateIn=${dates.dateIn}&dateFn=${dates.dateFn}`;
     download(downloadUrl, reportMetadata.fileName)
      onClose();
    } catch (error) {
      onClose();
    }
  }

  return (
    <div className={styles.invoice_detail__container}>
      {error !== "" && <p>{error}</p>}
      <p className={styles.invoice_detail_description}>
        Favor de insertar las fechas, para el reporte de caja detallado.
      </p>
      <Grid container justify="center" spacing="4">
        <Grid item>
          <TextField
            label="Fecha de Inicio"
            type="date"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            fullWidth
            name="dateIn"
            value={dates.dateIn}
            onChange={handleChangeDate}
            error={!!error}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Fecha Fin"
            type="date"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            fullWidth
            name="dateFn"
            value={dates.dateEnd}
            onChange={handleChangeDate}
            error={!!error}
          />
        </Grid>
        <Grid container justify="center" spacing="4">
          <Grid item>
            <Button variant="contained" color="primary" onClick={getReport}>
              Generar
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} color="secondary">Cancelar</Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default InvoiceDetail;
