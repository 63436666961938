import React, { useMemo } from "react";
import floor from "lodash.floor";
import { Container, Box } from "@mui/material";
import clsx from "clsx";
import { getMonthName } from "../../../../utils/utils";
import { formatCurrency, calculateAmount } from "../../../../utils/utils";
import logo from "../../../../assests/anestesiologia.png";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import styles from "./invoicePrintPage.module.scss";

function InvoicePrintPage({
  client,
  date,
  services,
  id,
  paymentType,
  notes,
  referenceMembers,
  voucherNumber,
  isMember,
  applyItebis,
  itebis = 18,
  currencyType,
  currencyRate,
  isHalfPaper,
  ownerType,
  taxpayerRegistryNumber,
  taxReceiptNumber,
  invoiceType,
  taxNumberInformation,
}) {
  const [ storageOrganization ] = useLocalStorage("organization", null);

  const organization = useMemo(()=> (JSON.parse(storageOrganization)),[storageOrganization]);
  const totalBruto = useMemo(() => {
    if (services?.length) {
      return services.reduce(
        (total, service) => total + service.quantity * service.price,
        0
      );
    }
    return 0;
  }, [services]);

  const totalDisc = useMemo(() => {
    if (services?.length) {
      return services.reduce((totalDisc, service) => {
        let grossTotal = +service.quantity * +service.price;
        totalDisc = totalDisc + grossTotal * (+service.discount / 100);
        return totalDisc;
      }, 0);
    }
    return 0;
  }, [services]);

  const totalNeto = totalBruto - totalDisc;

  let totalNetoWithItebis = 0;
  let totalForItebis = 0;

  if (applyItebis) {
    totalNetoWithItebis = services.length
      ? services.reduce((totalWithDisc, service) => {
          let total = +service.quantity * +service.price;

          let totalDisc = total - total * (+(service.discount || 0) / 100);

          if (!service.excludeTax) {
            totalForItebis = totalForItebis + totalDisc;

            totalWithDisc = floor(
              totalWithDisc + (totalDisc + (totalDisc * itebis) / 100),
              2
            );
          } else {
            totalWithDisc = floor(totalWithDisc + totalDisc, 2);
          }

          return totalWithDisc;
        }, 0)
      : 0;
  }

  const totalItebis = applyItebis ? (totalForItebis * itebis) / 100 : 0;

  const totalGrand = applyItebis ? totalNetoWithItebis : totalNeto;

  const paymentTypeDescription = useMemo(() => {
    let paymentTypeStr = "";
    switch (paymentType) {
      case "cash":
        paymentTypeStr = "Efectivo";
        break;
      case "bankTransfer":
        paymentTypeStr = "Transferencia Bancancaria";
        break;
      case "creditCard":
        paymentTypeStr = "Tarjeta de credito";
        break;
      case "bankDeposit":
        paymentTypeStr = "Deposito Bancario";
        break;
      default:
        paymentTypeStr = "Efectivo";
        break;
    }
    return paymentTypeStr;
  }, [paymentType]);

  const dateType = new Date(date);

  let isSameCurrency = true;
  let lastCurrency;

  if (services?.length) {
    for (let x = 0; x < services.length; x++) {
      if (lastCurrency && lastCurrency !== services[x].currency) {
        isSameCurrency = false;
      }
      lastCurrency = services[x].currency;
    }
  }

  return (
    <div className={styles.containerInvoice}>
      <div className={styles.invoice_brand_row}>
        <img
          className={clsx({
            [styles.invoice_brand_logo]: !isHalfPaper,
            [styles.invoice_logo_smaller]: isHalfPaper,
          })}
          src={organization.logo}
          alt="sociedad anestiologia logotipo"
        />
        <div>
          <h1
            className={clsx({
              [styles.invoice_brand_name]: !isHalfPaper,
              [styles.brand_name_small]: isHalfPaper,
            })}
          >
            {organization.name}
          </h1>
          <p
            className={clsx({
              [styles.invoice_rnc]: !isHalfPaper,
              [styles.rnc_small]: isHalfPaper,
            })}
          >
            {organization.registerNumber}
          </p>
        </div>
      </div>

      {!!date && (
        <p
          className={clsx({
            [styles.invoice_date]: !isHalfPaper,
            [styles.date_small]: isHalfPaper,
          })}
        >
          {dateType.getUTCDate()}/{dateType.getUTCMonth() + 1}/
          {dateType.getUTCFullYear()}
        </p>
      )}
      {id ? (
        <p
          className={clsx({
            [styles.invoice_num]: !isHalfPaper,
            [styles.invoice_num_small]: isHalfPaper,
          })}
        >{`No. ${id}`}</p>
      ) : null}
      <p
        className={clsx({
          [styles.invoice_status]: !isHalfPaper,
          [styles.status_small]: isHalfPaper,
        })}
      >
        {id ? "Factura" : "Cotización"}
      </p>
      {invoiceType === "credit" ? (
        <div className={styles.invoice_credit_info_container}>
          <p className={styles.invoice_credit}>Crédito</p>
          <p className={styles.invoice_credit_panding}>Esta factura tiene un pendiente a pagar de: </p>
        </div>
      ) : null}
      <div className={styles.invoice_inner_status}>
        {!!taxpayerRegistryNumber ? (
          <div>
            <p className={styles.invoice_register_number_description}>
              RNC: {taxpayerRegistryNumber}
            </p>
            <p className={styles.invoice_register_number_description}>
              NCF: {taxReceiptNumber}
            </p>
            <p className={styles.invoice_register_number_information}>
              Vencimineto de NCF: {getMonthName(taxNumberInformation.endMonth)}/
              {taxNumberInformation.endYear}
            </p>
          </div>
        ) : null}
        <div>
          <p
            className={clsx({
              [styles.invoice_payment_type_description]: !isHalfPaper,
              [styles.payment_type_description_small]: isHalfPaper,
            })}
          >{`Tipo de pago: ${paymentTypeDescription}`}</p>
          {paymentType === "bankTransfer" || paymentType === "bankDeposit" ? (
            <>
              {currencyType === "USD" ? (
                <>
                  <p className={styles.text_bank_account}>Banco BHD</p>
                  <p className={styles.text_bank_account}>Moneda USD</p>
                  <p className={styles.text_bank_account}>
                    Cuenta No. 1023516-002-1
                  </p>
                </>
              ) : (
                <>
                  <p className={styles.text_bank_account}>Banco BHD</p>
                  <p className={styles.text_bank_account}>Moneda DOP</p>
                  <p className={styles.text_bank_account}>
                    Cuenta No. 1023516-001-3
                  </p>
                </>
              )}
            </>
          ) : null}
        </div>
      </div>
      <div
        className={clsx({
          [styles.invoice_client_container]: !isHalfPaper,
          [styles.client_container_small]: isHalfPaper,
        })}
      >
        <p
          className={clsx({
            [styles.invoice_format__full_name]: !isHalfPaper,
            [styles.format__full_name_small]: isHalfPaper,
          })}
        >
          <span className={styles.invoice_label}>Cliente:</span> {client.name}
        </p>
        {ownerType !== "member" ? (
          <>
            {ownerType === "company" ? (
              <p className={styles.invoice_format__address}>{client.address}</p>
            ) : null}

            <p className={styles.invoice_format__identifier}>
              <span className={styles.invoice_label}>
                {ownerType === "person" ? "Referencia" : "RNC"}:
              </span>{" "}
              {client.identifier}
            </p>
          </>
        ) : null}
      </div>
      <div className={styles.invoice_format__detail_container}>
        <h4 className={styles.invoice_format__detail_title}>Detalles</h4>
        <hr />
        <div className={styles.invoice_format__services_header}>
          <div
            className={clsx([
              styles.invoice_format__service_title,
              styles.invoice_format__description,
            ])}
          >
            Descripción
          </div>
          <div
            className={clsx([
              styles.invoice_format__service_title,
              styles.invoice_format__quantity,
            ])}
          >
            Cant
          </div>
          <div
            className={clsx([
              styles.invoice_format__service_title,
              styles.invoice_format__price,
            ])}
          >
            Price
          </div>
          <div
            className={clsx([
              styles.invoice_format__service_title,
              styles.invoice_format__total,
            ])}
          >
            Total Bruto
          </div>
          <div
            className={clsx([
              styles.invoice_format__service_title,
              styles.invoice_format__total,
            ])}
          >
            Desc
          </div>
          {applyItebis ? (
            <div
              className={clsx([
                styles.invoice_format__service_title,
                styles.invoice_format__total,
              ])}
            >
              ITBIS
            </div>
          ) : null}
          <div
            className={clsx([
              styles.invoice_format__service_title,
              styles.invoice_format__total,
            ])}
          >
            Total Neto
          </div>
        </div>
        <div className={styles.invoice_format__services_body}>
          {services?.map((service, index) => {
            return (
              <div
                key={`invoice_services_${index}`}
                className={styles.invoice_format__row}
              >
                <div
                  className={`${styles.text_base} ${styles.invoice_format__description}`}
                >
                  {service.description}
                </div>
                <div
                  className={`${styles.text_base} ${styles.invoice_format__quantity}`}
                >
                  {service.quantity}
                </div>
                <div
                  className={`${styles.text_base} ${styles.invoice_format__price}`}
                >
                  {formatCurrency(
                    calculateAmount(
                      service.currency,
                      currencyType,
                      currencyRate
                    )(service.price)
                  )}
                </div>
                <div
                  className={`${styles.text_base} ${styles.invoice_format__total}`}
                >
                  {formatCurrency(
                    calculateAmount(
                      service.currency,
                      currencyType,
                      currencyRate
                    )(service.quantity * service.price)
                  )}
                </div>
                <div
                  className={`${styles.text_base} ${styles.invoice_format__price}`}
                >
                  {service.discount}%
                </div>
                {applyItebis ? (
                  <div
                    className={`${styles.text_base} ${styles.invoice_format__price}`}
                  >
                    {formatCurrency(
                      calculateAmount(
                        service.currency,
                        currencyType,
                        currencyRate
                      )(
                        service.excludeTax
                          ? 0
                          : ((service.quantity * service.price -
                              (service.quantity *
                                service.price *
                                service.discount) /
                                100) *
                              itebis) /
                              100
                      )
                    )}
                  </div>
                ) : null}
                <div
                  className={`${styles.text_base} ${styles.invoice_format__total}`}
                >
                  {formatCurrency(
                    calculateAmount(
                      service.currency,
                      currencyType,
                      currencyRate
                    )(
                      service.quantity * service.price -
                        (service.discount
                          ? (service.quantity *
                              service.price *
                              service.discount) /
                            100
                          : 0) +
                        (applyItebis
                          ? service.excludeTax
                            ? 0
                            : service.quantity * service.price -
                              (service.discount
                                ? (service.quantity *
                                    service.price *
                                    service.discount) /
                                  100
                                : 0)
                          : 0)
                    ),
                    2
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {referenceMembers && referenceMembers.length ? (
          <div className={styles.invoice_format__member_reference}>
            <h4 className={styles.invoice_format__member_reference_title}>
              Beneficiarios
            </h4>
            {referenceMembers.map((item, idx) => {
              return (
                <>
                  <p
                    key={`invoice-service-${idx}`}
                    className={styles.invoice_format_service_label}
                  >
                    {item.service.description}
                  </p>
                  {item.members.map((member, index) => {
                    return (
                      <p
                        key={`invoice-member-${index}`}
                        className={styles.invoice_format_member_label}
                      >
                        {member.fullName} - {member.identifier}
                      </p>
                    );
                  })}
                </>
              );
            })}
          </div>
        ) : null}
        <div className={styles.invoice_format__footer}>
          <div className={styles.invoice_total_container}>
            <p className={styles.invoice_total_description}>Total Desc.</p>
            <p className={styles.invoice_format_total_price}>
              {`${currencyType === "USD" ? "US" : "RD"}`}$
              {formatCurrency(
                calculateAmount(
                  services[0].currency,
                  currencyType,
                  currencyRate
                )(totalDisc),
                2
              )}
            </p>
          </div>
          <div className={styles.invoice_total_container}>
            <p className={styles.invoice_total_description}>Total Bruto</p>
            <p className={styles.invoice_format_total_price}>
              {`${currencyType === "USD" ? "US" : "RD"}`}$
              {formatCurrency(
                calculateAmount(
                  services[0].currency,
                  currencyType,
                  currencyRate
                )(totalNeto),
                2
              )}
            </p>
          </div>
          <div className={styles.invoice_total_container}>
            <p className={styles.invoice_total_description}>Total ITBIS</p>
            <p className={styles.invoice_format_total_price}>
              {`${currencyType === "USD" ? "US" : "RD"}`}$
              {formatCurrency(
                calculateAmount(
                  services[0].currency,
                  currencyType,
                  currencyRate
                )(totalItebis),
                2
              )}
            </p>
          </div>

          <div className={styles.invoice_total_container}>
            <p className={styles.invoice_total_description}>Total Neto</p>
            <p className={styles.invoice_format__net_total}>
              {`${currencyType === "USD" ? "US" : "RD"}`}$
              {formatCurrency(
                calculateAmount(
                  services[0].currency,
                  currencyType,
                  currencyRate
                )(totalGrand),
                2
              )}
            </p>
          </div>
        </div>
        <p>{notes}</p>
      </div>
    </div>
  );
}

export default InvoicePrintPage;
