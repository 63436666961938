import React from "react";
import { Button } from "@mui/material";
import clsx from "clsx";
import styles from "./buttonSpace.module.scss";

const ButtonSpace = ({
  editMember,
  isEdit,
  isSavingPost,
  saveMember,
  noStyle = true,
  permission,
}) => {
  return (
    <div className={clsx({ [styles.buttonContainer]: noStyle })}>
      {isEdit ? (
        <Button
          variant="contained"
          color="primary"
          onClick={editMember}
          disabled={isSavingPost || !permission.canEdit}
        >
          Editar
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={saveMember}
          disabled={isSavingPost || !permission.canCreate}
        >
          Guardar
        </Button>
      )}
    </div>
  );
};

export default ButtonSpace;
