import React, { useEffect, useState, useMemo } from "react";
import { Grid, Box, Paper, Breadcrumbs, Typography } from "@mui/material";
import { AlertDialog, SearchBox } from "../../../components";
import TableInvoice from "./TableInvoice";
import { getInvoices, changeInvoiceStatus } from "../../../services/invoiceAPI";
import InvoiceFormat from "../components/InvoicePrintPage/InvoicePrintPage"; //"../InvoiceFormat";
import StatusBar from "./StatusBar";
import useLocalStorage from "../../../hooks/useLocalStorage";
import styles from "./list.module.scss";

const initialFilter = {
  status: "paid",
  page: 0,
  fullNameSearch: "",
};

const ITEM_PEER_PAGE = 10;
function ListContainer() {
  const [invoices, setInvoices] = useState({ total: 0, invoices: [] });
  const [searchLoading, setSearchLoading] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [invoiceToModified, setInvoiceToModified] = useState(null);
  const [showPrint, setShowPrint] = useState(false);
  const [invoiceToPrint, setInvoiceToPrint] = useState({});
  const [showDeniedMsg, setShowDeniedMsg] = useState(false);

  const [permissionsStorage] = useLocalStorage("permissions");

  const permissions = useMemo(() => {
    if (!permissionsStorage) {
      return {};
    }
    return (
      JSON.parse(permissionsStorage).find((p) => p.modelName === "invoice") || {}
    );
  }, [permissionsStorage]);

  function getInvoiceByMember(text) {
    setFilter((prevFilter) => ({
      ...prevFilter,
      fullNameSearch: text,
    }));
  }

  async function nextPage(e, page) {
    setFilter((prevFilter) => ({
      ...prevFilter,
      page: page,
    }));
  }

  function loadPrintAndShow(invoice) {
    const toPrint = {
      ...invoice,
      client: {
        name:  invoice?.member?.fullName || invoice?.company?.name || invoice.owner.name,
        identifier: invoice?.member?.identifier || invoice?.company?.registerNumber || invoice.owner.reference,
        address: invoice?.company?.address || ''
      },
      isMember: !!invoice.member,
    };

    if (invoice.taxpayerRegistryNumber && invoice.tax_number) {
      toPrint.taxReceiptNumber = invoice.tax_number.taxNumberComplete;
      toPrint.taxNumberInformation = {
        endMonth: invoice?.tax_receipt_seq?.endMonth,
        endYear: invoice?.tax_receipt_seq?.endYear
      }
    }
    
    setInvoiceToPrint(toPrint);
    setShowPrint(true);
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  function loadInvoiceByStatus(status) {
    setFilter((prevFilter) => ({
      ...prevFilter,
      status,
    }));
  }

  function handlerTemporalInvoice(invoice) {
    setInvoiceToModified(invoice);
  }

  async function handlerChangeInvoiceStatus(reason) {
    try {
      await changeInvoiceStatus(
        invoiceToModified._id,
        invoiceToModified.status === "paid" ? "cancel" : "paid",
        reason
      );
      setInvoiceToModified(null);
      setSearchLoading(true);
      let { body: payload } = await getInvoices(
        filter.status,
        filter.fullNameSearch,
        filter.page
      );
      if (payload.success) {
        setInvoices({ total: payload.totalInvoices, invoices: payload.data });
      }
      setSearchLoading(false);
    } catch (error) {
      setInvoiceToModified(null);
    }
  }
  useEffect(() => {
    (async () => {
      try {
        if (permissions.canRead) {
        setSearchLoading(true);
        let { body: payload } = await getInvoices(
          filter.status,
          filter.fullNameSearch,
          filter.page
        );
        if (payload.success) {
          setInvoices({ total: payload.totalInvoices, invoices: payload.data });
        }
      } else {
        setShowDeniedMsg(true);
      }
      } catch (error) {
      } finally {
        setSearchLoading(false);
      }
    })();
  }, [filter.fullNameSearch, filter.page, filter.status]);

  if (showDeniedMsg) {
    return (
      <div><h1>No tiene acceso</h1><p>Su usuario no tiene permiso para ver las facturas.</p></div>
    )
  }

  return (
    <>
      <Box displayPrint="none">
        <Paper>
          <Breadcrumbs>
            <Typography>Inicio</Typography>
            <Typography color="textPrimary">Lista de facturas</Typography>
          </Breadcrumbs>
        </Paper>
        <div className={styles.list_container}>
          {invoiceToModified && (
            <AlertDialog
              isOpen={true}
              title={"Modificar factura"}
              message={`Desea ${
                invoiceToModified.status === "paid" ? "CANCELAR" : "REACTIVAR"
              }  No. ${invoiceToModified.id} De ${
                invoiceToModified?.member?.fullName || invoiceToModified?.company?.name || invoiceToModified?.owner?.name
              }`}
              withReason
              onCancel={() => setInvoiceToModified(null)}
              onOk={handlerChangeInvoiceStatus}
            />
          )}
          <Grid
            container className={styles.searchList}
          >
            <Grid xs={8} item>
              <SearchBox
                disable={searchLoading}
                getByName={getInvoiceByMember}
                placeholder="No. Factura, Nombres o Apellidos(mínimo 3 caracteres)"
                extraFilters={false}
              />
            </Grid>
            <Grid xs={4} item>
              <StatusBar
                status={filter.status}
                loadInvoiceByStatus={loadInvoiceByStatus}
              />
            </Grid>
          </Grid>
          <TableInvoice
            invoices={invoices}
            stageForChangeStatus={handlerTemporalInvoice}
            loading={searchLoading}
            handleChangePage={nextPage}
            positionStart={ITEM_PEER_PAGE * filter.page}
            loadPrintAndShow={loadPrintAndShow}
            page={filter.page}
          />
        </div>
      </Box>
      {showPrint && <InvoiceFormat {...invoiceToPrint} isHalfPaper={false} />}
    </>
  );
}

export default ListContainer;
