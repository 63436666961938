import React, { useState } from "react";
import Select from "react-select";
import { Fab, Checkbox, FormControlLabel } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InvoiceOwner from "../../../pages/ChargeManagement/components/InvoiceOwner/InvoiceOwner";
import { getMember } from "../../../services/memberAPI";
import MemberList from "./MemberList";
import Companion from "../Companion";
import styles from "./memberReference.module.scss";

function MemberReference({
  addReferenceMember,
  removeReferenceMember,
  services,
  references,
}) {
  const [withReference, setWithReference] = useState(false);
  const [service, setService] = useState({
    label: "",
    value: "",
  });
  const [member, setMember] = useState({
    label: "",
    value: "",
  });
  const [isCompanion, setIsCompanion] = useState(false);

  function onDropDownChange(newValue, { name }) {
    if (name === "service") {
      setService(newValue);
    } else if (name === "member") {
      setMember({
        label: newValue.label,
        value: newValue.body.identifier,
      });
    }
  }

  async function fetchMember(inputValue, callback) {
    try {
      let { body: payload } = await getMember("all", 0, 50, inputValue);
      let members = [];
      if (payload.data && payload.data.length) {
        members = payload.data.map((member) => ({
          value: member.id,
          label: member.familyName + " " + member.names,
          body: member,
        }));
      }
      callback(members);
    } catch (error) {
      console.error("ERROR ", error);
    }
  }

  function onDropDownReferenceMember() {
    if (service.value !== "" && member.value !== "") {
      addReferenceMember(service, member);
      setMember({
        label: "",
        value: "",
      });
    }
  }

  const onChangeCompanion = (e) => {
    setMember({
      label: "",
      value: "",
    });
    setIsCompanion(e.target.checked);
  };
  const lookServiceIdx = references.findIndex(
    (item) => item.service.value === service.value
  );
  const isDisabled = references[lookServiceIdx]
    ? references[lookServiceIdx].members.length === parseInt(service.quantity)
    : false;

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={withReference}
              onChange={() => setWithReference((prev) => !prev)}
            />
          }
          label="Agregar acompañante"
        />
        {withReference ? (
          <>
            <div>
              <div className={styles.services}>
                <label>Servicios</label>
                <Select
                  name="service"
                  className={`service_drop_down`} //${!!validState.service ? 'service_drop_down_error' : ''}
                  classNamePrefix="service"
                  options={services}
                  onChange={onDropDownChange}
                  isDisabled={false}
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCompanion}
                    onChange={onChangeCompanion}
                  />
                }
                label="No miembro"
              />
              {isCompanion ? (
                <Companion onInputChange={setMember} member={member} />
              ) : (
                <InvoiceOwner
                  ownerTypeOveright="member"
                  fetchItems={fetchMember}
                  name="member"
                  onDropDownChange={onDropDownChange}
                />
              )}
            </div>
            <div className={styles.btnContainer}>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                //className={classes.fab}
                onClick={onDropDownReferenceMember}
                disabled={isDisabled}
              >
                <AddIcon />
              </Fab>
            </div>
          </>
        ) : null}
      </div>
      {withReference ? (
        <MemberList
          references={references}
          removeReferenceMember={removeReferenceMember}
          isCompanion={isCompanion}
        />
      ) : null}
    </div>
  );
}

export default MemberReference;
