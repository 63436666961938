import React, { useState, useEffect, useMemo } from "react";
import { Container, Breadcrumbs, Link, Typography, Paper } from "@mui/material";
import { useParams, useNavigate } from "react-router";
import {
  postMember,
  getMemberByIdentifier,
  putMember,
  setPromoteAspirant
} from "../../services/memberAPI";

import RowSettings from "../../components/memberForm/rowSettings/RowSettings";
import PersonalInfo from "../../components/memberForm/personalInfo/PersonalInfo";
import { AspiringForm } from "../../components/Aspiring/AspiringForm";
import { Documents } from "../../components/Aspiring/Documents";
import { isEmpty, isEmail, onlyNumber } from "../../utils/validators";
import DecisionButtons from "../../components/Aspiring/DecisionButtons/DecisionButtons";
import { scrollTop, transformText } from "../../utils/utils";
import { useToastSetter } from "../../context/ToastContext";
import useLocalStorage from "../../hooks/useLocalStorage";

const initialState = {
  names: "",
  searchableName: "",
  familyName: "",
  searchableFamilyName: "",
  identifier: "",
  registrationDate: "",
  street: "",
  number: "",
  city: "",
  phone: "",
  cellphone: "",
  email: "",
  municipality: "",
  providence: "",
  region: "",
  isUnCompleted: false,
  isDeceased: false,
  codeSchool: "",
  membershipToPay: 0,
  sequence: 0,
  presidentRole: false,
  isAspiring: true,
  documents: {
    generalDoctorTitle: false,
    letterForAdmission: false,
    residencyLetterAcceptance: false,
    medicalCollegeCardAndCertification: false,
    identificationDocument: false,
    two2x2photos: false,
    inscription: false,
  },
  status: "active",
};

const initPostValidation = {
  names: true,
  familyName: true,
  identifier: true,
  registrationDate: true,
  cellphone: true,
  email: true,
  municipality: true,
  providence: true,
  region: true,
  schoolOrigin: true,
  university: true,
  levelSDAentry: true,
};

function _phoneUnTransform(phone) {
  return phone
    .replace("(", "")
    .replace(")", "")
    .replace("-", "")
    .replace(" ", "")
    .trim();
}

const Aspiring = () => {
  const [form, setForm] = useState(initialState);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [postValidation, setPostValidation] = useState(initPostValidation);

  const { aspiranteId } = useParams();
  const navigate = useNavigate();
  const setToast = useToastSetter();

  const [permissionsStorage] = useLocalStorage("permissions");

  const permissions = useMemo(() => {
    if (!permissionsStorage) {
      return {};
    }
    return (
      JSON.parse(permissionsStorage).find((p) => p.modelName === "member") || {}
    );
  }, [permissionsStorage]);

  function handleFormCheckbox({ target: { checked, name } }) {
    setForm({
      ...form,
      [name]: checked,
    });
  }

  function handleForm({ target: { value, name } }) {
    let data = {
      [name]: value,
    };
    if (name === "names") {
      data.searchableName = value.toLowerCase();
    } else if (name === "familyName") {
      data.searchableFamilyName = value.toLowerCase();
    }
    setForm({
      ...form,
      ...data,
    });
  }

  function handlerMunicipality(
    { value: municipality },
    { value: providence },
    { value: region }
  ) {
    setForm({
      ...form,
      municipality: municipality,
      providence: providence,
      region: region,
    });
  }

  function onCheckDocumentItem({ target: { checked, name } }) {
    setForm({
      ...form,
      documents: {
        ...form.documents,
        [name]: checked,
      },
    });
  }

  function validatePost(post) {
    try {
      let isValid = true;
      const fieldsToValidate = [
        "names",
        "familyName",
        "identifier",
        "registrationDate",
        "cellphone",
        "email",
        "municipality",
        "providence",
        "region",
        "membershipToPay",
      ];

      const validation = fieldsToValidate.reduce((acc, key) => {
        let regOnlyNegativeAndPositveNumber = /^-?[0-9]+$/g;
        if (key === "email" && post[key] !== null && !isEmail(post[key])) {
          isValid = false;
          acc[key] = false;
        } else if (
          key === "membershipToPay" &&
          !regOnlyNegativeAndPositveNumber.test(post[key])
        ) {
          isValid = false;
          acc[key] = false;
        } else if (
          key === "identifier" &&
          post[key].replace(/-/g, "").trim().length !== 11
        ) {
          isValid = false;
          acc[key] = false;
        }

        if (!post[key] && post[key] !== 0) {
          acc[key] = !!post[key];
          isValid = false;
        }

        return acc;
      }, {});

      setPostValidation((prev) => ({
        ...prev,
        ...validation,
      }));

      if (!isValid) {
        scrollTop();
      }
      return isValid;
    } catch (error) {
      console.error("Validation error ", error);
    }
  }

  function transformData(data) {
    let dataForm = {...data};
    dataForm.names = transformText(dataForm.names);
    dataForm.familyName = transformText(dataForm.familyName);
    dataForm.searchableName = dataForm.searchableName.trim();
    dataForm.searchableFamilyName = dataForm.searchableFamilyName.trim();
    dataForm.email =
    dataForm.email !== "" ? dataForm.email.toLocaleLowerCase() : dataForm.email;
    dataForm.cellphone = _phoneUnTransform(dataForm.cellphone);
    dataForm.phone = _phoneUnTransform(dataForm.phone);
    dataForm.identifier = form.identifier.replace(/-/g, "").trim();

    return dataForm;
  }
  async function onEditClick() {
        setIsSaving(true);
        if (validatePost(form)) {
          try {
           const dataForm = transformData(form);
            let { body: payload } = await putMember(dataForm);
            scrollTop();
            if (payload.success) {
              setToast({
                variant: "success",
                message: `El usuario se actualizado con exito!`,
                open: true,
              });
              window.setTimeout(() => {
                navigate("/miembros", { state: { member: form } });
              }, 1300);
            } else {
              setToast({
                variant: "warning",
                message: `Hubo un error al intentar actualizar`,
                open: true,
              });
            }
          } catch (error) {
            console.log("Error al actualizar ", error);
            scrollTop();
            setToast({
              variant: "error",
              message: `Hubo un error al intentar actualizar`,
              open: true,
            });
          }
        }
        setIsSaving(false);
  }

  async function onSaveClick() {
    setIsSaving(true);
    if (validatePost(form)) {
      try {
        const dataForm = transformData(form);

        let { body: payload } = await postMember(dataForm);
        if (payload.success) {
          setToast({
            variant: "success",
            message: `El usuario se guardo con exito!`,
            open: true,
          });
          window.setTimeout(() => {
            navigate("/miembros");
          }, 1300);
        } else {
          setToast({
            variant: "error",
            message: `Hubo un error al intentar guardar, verifique si la cedula es unica`,
            open: true,
          });
        }
        scrollTop();
      } catch (error) {
        console.log("ERR ", error, error.body.message);
        if (error?.body?.message) {
          setToast({
            variant: "error",
            message: error.body.message,
            open: true,
          });
        } else {
          setToast({
            variant: "error",
            message: `Hubo un error al intentar guardar`,
            open: true,
          });
        }
        scrollTop();
      }
    }
    setIsSaving(false);
  }

  async function promoteAspirant() {
    try {
     const result = await setPromoteAspirant(form.id)
     scrollTop();
     if (result) {
      setToast({
        variant: "success",
        message: `El usuario se promovido con exito!`,
        open: true,
      });
      window.setTimeout(() => {
        navigate("/miembros");
      }, 1300);
     } else {
      setToast({
        variant: "warnning",
        message: `El usuario NO puede ser promovido`,
        open: true,
      });
     }
    } catch(e) {
      if (e?.body?.message) {
        setToast({
          variant: "error",
          message: e.body.message,
          open: true,
        });
      } else {
        setToast({
          variant: "error",
          message: `Hubo un error al intentar promover el aspirante`,
          open: true,
        });
      }
      scrollTop();
    }
  }

  useEffect(() => {
    if (aspiranteId) {
      async function loadMember() {
        try {
          let { body: member } = await getMemberByIdentifier(aspiranteId);
          if (member.success) {
            setEdit(true);
            setForm({
              ...form,
              ...member.data,
            });
          }
        } catch (error) {
          console.error("Error to load member ", error);
        }
      }
      loadMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspiranteId]);

  return (
    <Container fixed maxWidth="sm">
      <Paper>
        <Breadcrumbs aria-label="Breadcrumb">
          <Link color="inherit" href="/" onClick={() => {}}>
            Inicio
          </Link>
          <Typography color="textPrimary">Formulario aspirante</Typography>
        </Breadcrumbs>
      </Paper>
      <form noValidate autoComplete="off">
        <RowSettings
          form={form}
          handleFormCheckbox={handleFormCheckbox}
          handleForm={handleForm}
          isAspiring
        />

        <PersonalInfo
          postValidation={postValidation}
          form={form}
          handleForm={handleForm}
          handlerMunicipality={handlerMunicipality}
          handleFormCheckbox={handleFormCheckbox}
        />

        <AspiringForm
          form={form}
          handleForm={handleForm}
          errors={postValidation}
        />

        <Documents
          checkDocumentItem={onCheckDocumentItem}
          {...form.documents}
        />
       <DecisionButtons
          disabledPromotion={form.membershipToPay > 0 && form?.id}
          onClickPromoteAspirant={promoteAspirant}
          isEdit={isEdit}
          onEditClick={onEditClick}
          isSavingPost={isSaving}
          onSaveClick={onSaveClick}
          permission={permissions}
        />
      </form>
    </Container>
  );
};

export default Aspiring;
