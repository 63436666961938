import React from "react";
import logo from "../../../assests/anestesiologia.png";
import { formatCurrency } from "../../../utils/utils";
import clsx from "clsx";
import styles from "./invoiceCreditPrint.module.scss";

const InvoiceCreditPrint = ({
  isHalfPaper,
  date,
  invoiceId,
  id = 8,
  owner,
  amount,
  notes,
  mainInvoice,
  totalQuotasPayed
}) => {
  const dateVarible = new Date(date);
  const mainTotal = mainInvoice.currencyRate * mainInvoice.total;
  return (
    <div className={clsx(styles.contianer, styles.containerHalf)}>
      <div className={styles.brand_row}>
        <img
          className={clsx({
            [styles.brand_logo]: !isHalfPaper,
            [styles.brand_logo_smaller]: isHalfPaper,
          })}
          src={logo}
          alt="sociedad anestiologia logotipo"
        />
        <div>
          <h1
            className={clsx({
              [styles.brand_name]: !isHalfPaper,
              [styles.brand_name_small]: isHalfPaper,
            })}
          >
            SOCIEDAD DOMINICANA DE ANESTESIOLOGIA INC.
          </h1>
          <p
            className={clsx({
              [styles.rnc]: !isHalfPaper,
              [styles.rnc_small]: isHalfPaper,
            })}
          >
            RNC: 4-30-00485-5
          </p>
        </div>
      </div>
      <div>
        <h4 className={styles.title}>Factura parcial</h4>
        <div className={styles.squenceRow}>
          <div>
            <p className={styles.invoiceNum}>
              <span className={styles.bolder}>No. Fact</span>
              {invoiceId}
            </p>
            <p className={styles.abonoNum}>
              <span className={styles.bolder}>No.</span>
              {id}
            </p>
          </div>
          {!!date && (
            <p
              className={clsx({
                [styles.invoice_date]: !isHalfPaper,
                [styles.date_small]: isHalfPaper,
              })}
            >
              {dateVarible.getUTCDate()}/{dateVarible.getUTCMonth() + 1}/
              {dateVarible.getUTCFullYear()}
            </p>
          )}
        </div>
        <div className={styles.ownerContainer}>
          <label className={styles.forWho}>Titular:</label>
          <p className={styles.owner}>{owner}</p>
        </div>
        <p className={styles.amount}>
          <span className={styles.symbol}>{mainInvoice.currencyType}$</span> {formatCurrency(amount)}
        </p>
        <div className={styles.detailQuota}>
          <div className={styles.notesContainer}>
            <label className={styles.bolder}>Nota</label>
            <p className={styles.notes}>{notes}</p>
          </div>
          <div className={styles.detail}>
            <div className={styles.leyend}>
              <label>Pagado</label>
              <p className={styles.amount_leyend}>{totalQuotasPayed}</p>
            </div>
            <div className={styles.leyend}>
              <label>Total original</label>
              <p className={styles.amount_leyend}>{mainTotal}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceCreditPrint;
