import { Button } from "@mui/material";
import ButtonSpace from "../../memberForm/buttonSpace/ButtonSpace";
import styles from "./decisionButtons.module.scss";

const DecisionButtons = ({
  isEdit,
  onEditClick,
  isSaving,
  onSaveClick,
  disabledPromotion,
  onClickPromoteAspirant,
  permission
}) => {
  return (
    <div className={styles.container}>
      <Button
        variant="outlined"
        color="error"
        onClick={onClickPromoteAspirant}
        disabled={disabledPromotion}
      >
        Promover a miembro
      </Button>
      <ButtonSpace
        noStyle={false}
        isEdit={isEdit}
        editMember={onEditClick}
        isSavingPost={isSaving}
        saveMember={onSaveClick}
        permission={permission}
      />
    </div>
  );
};

export default DecisionButtons;
