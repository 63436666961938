
const API_URL_MONGO_STAGING = "http://157.245.240.131:8780/sisa/api/"; // Check this port number soon
const API_URL_MONGO_PROD = "http://157.245.240.131:8087/sisa/api/";
//https://sda.sociedadplus.com:8087/sisa/api/
const API_URL_MONGO = process.env.REACT_APP_DATABASE_ENV === 'staging' 
? API_URL_MONGO_STAGING : API_URL_MONGO_PROD;

const API_URL_DEV = "http://localhost:8086/sisa/api/";

const API_URL =  process.env.REACT_APP_NODE_ENV === "develop" ? 
API_URL_DEV :
API_URL_MONGO;

export {
  API_URL_MONGO,
  API_URL_DEV,
  API_URL
};
