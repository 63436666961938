import React, { useState } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from "@mui/material/Popper";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { ButtonLink } from "../..";
import styles from "./styles.module.scss";

function TopRow({permissions}) {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    
    <div className={styles.btnContainer}>
      <ButtonGroup
        variant="contained"
        aria-label="split button"
        ref={anchorRef}
        disabled={!permissions.canCreate}
      >
        <Button>Nuevo</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    key={1}
                    // disabled={index === 2}
                    // selected={index === selectedIndex}
                    //onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    <ButtonLink to="/miembros/nuevo">
                      <AddCircleOutlineOutlinedIcon />
                       Miembro
                    </ButtonLink>
                  </MenuItem>
                  <MenuItem key={2}>
                    <ButtonLink to="/miembros/aspirante" disabled={!permissions.custom_CanAspirant}>
                      <AddCircleOutlineOutlinedIcon />
                      Aspirante
                    </ButtonLink>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default TopRow;
